<template>
	<div>
		<v-container>
			<div class="mt-16">
				<div
					v-html="$t('app.home.accessibility.para_1')"
					class="text-h6 text-md-h5"
				/>
				<Title :text="$t('app.home.accessibility.heading_1')"></Title>
				<div
					v-html="$t('app.home.accessibility.para_2')"
					class="text-h6 text-md-h5"
				/>
				<div
					v-html="$t('app.home.accessibility.list_1')"
					class="text-h6 text-md-h5"
				/>
				<Title :text="$t('app.home.accessibility.heading_2')"></Title>
				<div
					v-html="$t('app.home.accessibility.para_3')"
					class="text-h6 text-md-h5"
				/>
			</div>
		</v-container>
	</div>
</template>

<script>
import Title from "@/components/website/Title.vue";

export default {
	name: "WebsiteAccessibility",

	components: {
		Title,
	},
};
</script>

<style></style>
