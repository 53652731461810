<template>
	<WebsiteAccessibility></WebsiteAccessibility>
</template>

<script>
import WebsiteAccessibility from "@/components/website/Accessibility.vue";

export default {
	name: "WebsiteAccessibilityPage",

	components: {
		WebsiteAccessibility,
	},
};
</script>

<style></style>
